import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import LastAlert from "../Cards/LastAlert";
import BatteryStatus from "../Cards/BatteryStatus";
import LastSeen from "../Cards/LastSeen";
import DeviceActionLogs from "../Cards/Alerts";

import FrameList from "../FrameList";
import MoldIndex from "../Cards/MoldIndex";
import MoldIndexGraph from "../Graphs/MoldIndexGraph";
import DeviceMetric from "../Cards/DeviceMetric";
import TemperatureGraph from "../Graphs/Temperature";
import HumidityGraph from "../Graphs/Humidity";
import C02Graph from "../Graphs/C02";
import { useSelector } from "react-redux";
import MotionCountGraph from "../Graphs/MotionCount";

export default function IrisMold(props) {
  let { isShare } = props;

  const [showMoldIndex, setShowMoldIndex] = React.useState(false);
  const [showMotion, setShowMotion] = React.useState(false);

  const deviceProfile = useSelector((state) => state.deviceProfile);

  useEffect(() => {
    if (deviceProfile) {
      //it might need json decoding, check
      if (typeof deviceProfile === "string") {
        let deviceProfileDecoded = JSON.parse(deviceProfile);

        if (!deviceProfileDecoded.fiveInOne) return;

        if (deviceProfileDecoded.fiveInOne.showMoldIndex) {
          setShowMoldIndex(true);
        } else {
          setShowMoldIndex(false);
        }

        if (deviceProfileDecoded.fiveInOne.showMotion) {
          setShowMotion(true);
        } else {
          setShowMotion(false);
        }
      }
    }
  }, [deviceProfile]);

  return (
    <>
      <Grid
        container
        spacing={1}
        alignItems={"stretch"}
        alignContent={"stretch"}
        sx={{ mt: 1 }}
      >
        <Grid item xs={12} md={isShare ? 6 : 4} sx={{ mt: 1 }}>
          <LastSeen />
        </Grid>
        <Grid item xs={12} md={isShare ? 6 : 4} sx={{ mt: 1 }}>
          <BatteryStatus field="batteryMv" />
        </Grid>

        {!isShare && (
          <>
            <Grid item xs={12} md={4} sx={{ mt: 1 }}>
              <LastAlert />
            </Grid>
          </>
        )}

        {showMoldIndex && (
          <>
            <Grid item xs={12} md={4} sx={{ mt: 1 }}>
              <MoldIndex />
            </Grid>

            <Grid item xs={12} md={8} sx={{ mt: 1 }}>
              <MoldIndexGraph />
            </Grid>
          </>
        )}

        <Grid item xs={12} md={showMotion ? 3 : 4} sx={{ mt: 1 }}>
          <DeviceMetric
            title="Temperature"
            value="temperature"
            icon="thermometer-half"
            iconSize="6x"
            aspect={10}
            metricWidth={3}
            graphWidth={12}
            unit="°C"
            textsize="h3"
          />
        </Grid>

        <Grid item xs={12} md={showMotion ? 3 : 4} sx={{ mt: 1 }}>
          <DeviceMetric
            title="Humidity"
            value="humidity"
            icon="tint"
            iconSize="6x"
            aspect={10}
            metricWidth={3}
            graphWidth={12}
            unit="%"
            textsize="h3"
          />
        </Grid>

        <Grid item xs={12} md={showMotion ? 3 : 4} sx={{ mt: 1 }}>
          <DeviceMetric
            title="Co2"
            value="co2"
            icon="cloud"
            iconSize="6x"
            aspect={10}
            metricWidth={3}
            graphWidth={12}
            unit="ppm"
            textsize="h3"
          />
        </Grid>

        {showMotion && (
          <Grid item xs={12} md={3} sx={{ mt: 1 }}>
            <DeviceMetric
              title="Motion Detected?"
              value="movement"
              icon="eye"
              iconSize="6x"
              aspect={10}
              metricWidth={3}
              graphWidth={12}
              unit=" Times"
              textsize="h3"
            />
          </Grid>
        )}

        <Grid item xs={12} md={showMotion ? 6 : 4} sx={{ mt: 1 }}>
          <TemperatureGraph
            title="Temperature"
            value="temperature"
            aspect={4}
          />
        </Grid>

        <Grid item xs={12} md={showMotion ? 6 : 4} sx={{ mt: 1 }}>
          <HumidityGraph
            title="Humidity"
            value="humidity"
            aspect={4}
            unit="%"
          />
        </Grid>

        <Grid item xs={12} md={showMotion ? 6 : 4} sx={{ mt: 1 }}>
          <C02Graph title="C02" value="co2" aspect={4} unit="ppm" />
        </Grid>

        {showMotion && (
          <Grid item xs={12} md={6} sx={{ mt: 1 }}>
            <MotionCountGraph value="movement" />
          </Grid>
        )}

        <Grid item xs={12} md={!isShare ? 6 : 12} sx={{ mt: 1 }}>
          <FrameList state="Motion" />
        </Grid>

        {!isShare && (
          <Grid item xs={12} md={6} sx={{ mt: 1 }}>
            <DeviceActionLogs />
          </Grid>
        )}
      </Grid>
    </>
  );
}
