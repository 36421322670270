import React, { useEffect, useState } from "react";
import { formatDate } from "../../Functions/formatDate";
import DataTableBase from "../DataTableBase";
import { useTranslation } from "react-i18next";
import { Grid, LinearProgress, Typography } from "@mui/material";
import convertMvToPercent from "../../Functions/convertMvToPercent";
import formatTemperature from "../../Functions/formatTemperature";
import DeleteFramesDialog from "../Dialogs/DeleteFramesDialog";
import { useSelector } from "react-redux";
import removeSelectedFrames from "../../Functions/removeSelectedFrames";
import { Check, Close } from "@mui/icons-material";

export default function FrameList(props) {
  const { t } = useTranslation("common");

  const sharingShown = props.isShare ? true : false;

  const [deleteFramesDialogOpen, setDeleteFramesDialogOpen] = useState(false);

  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const device = useSelector((state) => state.device);
  const deviceHistory = useSelector((state) => state.deviceHistory);
  const user = useSelector((state) => state.user);
  const selectedOrganisation = useSelector(
    (state) => state.selectedOrganisation
  );

  const deviceProfile = useSelector((state) => state.deviceProfile);
  let button1Label = "Button 1";
  let button2Label = "Button 2";
  let button3Label = "Button 3";
  let button4Label = "Button 4";

  if (deviceProfile.length) {
    //json decode deviceProfile

    try {
      let deviceProfileJSON = JSON.parse(deviceProfile);

      if (deviceProfileJSON.Rak4Button) {
        if (deviceProfileJSON.Rak4Button.button1) {
          button1Label = deviceProfileJSON.Rak4Button.button1;
        }
        if (deviceProfileJSON.Rak4Button.button2) {
          button2Label = deviceProfileJSON.Rak4Button.button2;
        }
        if (deviceProfileJSON.Rak4Button.button3) {
          button3Label = deviceProfileJSON.Rak4Button.button3;
        }
        if (deviceProfileJSON.Rak4Button.button4) {
          button4Label = deviceProfileJSON.Rak4Button.button4;
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  const [columns, setColumns] = useState([
    {
      name: "fcnt",
      label: "Frame Count",
      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: "time_created",
      label: t("common.date"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value === "N/A") {
            return <Typography>N/A</Typography>;
          }

          return <Typography>{formatDate(value, "LLL")}</Typography>;
        },
      },
    },
    {
      //battery
      name: "data",
      label: t("common.battery"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          let battery = 0;
          //get battery from data object
          try {
            let jsonData = value;
            jsonData = JSON.parse(jsonData);
            battery = jsonData.voltageMv || jsonData.batteryMv;
          } catch (error) {}

          if (battery === 0) {
            return <Typography>N/A</Typography>;
          }

          //draw a progress bar with the value and color it red if below 20% and yellow if below 50% and green if above 50%
          return (
            <Grid
              container
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
              spacing={1}
            >
              <Grid item xs={12} md={6}>
                <LinearProgress
                  variant="determinate"
                  value={convertMvToPercent(
                    battery,
                    device && device.device_brand ? device.device_brand : null
                  )}
                  max={100}
                  min={0}
                  sx={{ height: 6, borderRadius: "12px" }}
                  color={
                    convertMvToPercent(
                      battery,
                      device && device.device_brand ? device.device_brand : null
                    ) < 20
                      ? "error"
                      : convertMvToPercent(
                          battery,
                          device && device.device_brand
                            ? device.device_brand
                            : null
                        ) < 50
                      ? "warning"
                      : "primary"
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="body2" align="center">
                  {convertMvToPercent(
                    battery,
                    device && device.device_brand ? device.device_brand : null
                  )}
                  %
                </Typography>
              </Grid>
            </Grid>
          );
        },
      },
    },
  ]);

  useEffect(() => {
    if (deviceHistory && deviceHistory.length) {
      if (deviceHistory.length === 0) {
        return;
      } else {
        //loop through deviceHistory contains event_status, temperature, light, add them to the columns
        let tempColumns = [
          {
            name: "fcnt",
            label: "Frame Count",
            options: {
              display: false,
              filter: false,
              sort: false,
            },
          },
          {
            name: "time_created",
            label: t("common.date"),
            options: {
              filter: true,
              sort: true,
              customBodyRender: (value, tableMeta, updateValue) => {
                if (value === "N/A") {
                  return <Typography>N/A</Typography>;
                }

                return <Typography>{formatDate(value, "LLL")}</Typography>;
              },
            },
          },
        ];
        let hasTemp = false;
        let hasLight = false;
        let hasEventStatus = false;
        let hasSpeedKmph = false;
        let hasHeadingDeg = false;
        let hasLocation = false;
        let hasHumidity = false;
        let hasc02PPM = false;
        let hasLocationResolutionMethod = false;
        let hasMotion = false;
        let hasButtonPressed = false;
        let hasFlowRate = false;
        let hasPositiveCumulative = false;
        let hasInstantaneousVelocity = false;
        let hasInstantaneousFlow = false;
        let hasDistance = false;
        let hasPosition = false;
        let hasCurrent = false;
        let hasVoltage = false;
        let hasWatts = false;
        let hasSosEvent = false;
        let hasLeak = false;
        let hasTemp2 = false;
        let hasRH = false;
        let hasTVOC = false;
        let hasvoc_index = false;
        let haspm1_0 = false;
        let haspm2_5 = false;
        let haspm4_0 = false;
        let haspm10 = false;
        let hasMasspm1_0 = false;
        let hasMasspm2_5 = false;
        let hasMasspm4_0 = false;
        let hasMasspm10 = false;
        let hasAQI = false;
        let hasDryContact = false;
        let hasMin = false;
        let hasMax = false;
        let hasAvg = false;
        let hasRssi = false;
        let hasAnalog = false;
        let hasAmbientTemp = false;
        let hasWaterTempMin = false;
        let hasWaterTempMax = false;
        let hasTotalVolume = false;
        let hasErrorCode = false;
        let hasBatteryStatus = false;
        let hasLeakStatus = false;
        let hasButton1 = false;
        let hasButton2 = false;
        let hasButton3 = false;
        let hasButton4 = false;
        let hasTempWhite = false;
        let hasAccuracy = false;
        let hasPressure = false;
        let hasTemperatureSoil = false;
        let hasWaterSoil = false;
        let hasConductivitySoil = false;
        let hasSoilPH = false;
        let hasLeafTemperature = false;
        let hasLeafMoisture = false;
        let hasTemperature_1 = false;
        let hasTemperature_2 = false;
        let hasAmpHourAccumulation = false;
        let hasAverageAmps = false;
        let hasMaximumAmps = false;
        let hasMinimumAmps = false;
        let hasCapacitorVoltage = false;
        let hasTemperatureCelsius = false;
        let hasgas_pressure_psi = false;
        let hasidc_intput_ma = false;
        let hasDoor_open = false;
        let hasDoor_open2 = false;
        let hasPlugState = false;
        let hasInstantaneousActivePower = false;
        let hasInstantaneousPowerFactor = false;
        let hasTotalEnergy = false;
        let hasEnergyLastHour = false;
        let hasInstantaneousVoltage = false;
        let hasInstantaneousCurrent = false;
        let hasSound = false;
        let hasMovementCount = false;
        let hasMoldIndex = false;

        //loop through deviceHistory and check if it contains temperature, light, event_status, speedKmph, headingDeg
        //if any row contains any of these, add them to the columns
        for (let i = 0; i < deviceHistory.length; i++) {
          let data = deviceHistory[i].data;
          data = JSON.parse(data);

          if (data.total_energy !== undefined) {
            hasTotalEnergy = true;
          }

          if (data.energy_of_last_hour !== undefined) {
            hasEnergyLastHour = true;
          }

          if (data.instantaneous_voltage !== undefined) {
            hasInstantaneousVoltage = true;
          }

          if (data.instantaneous_current !== undefined) {
            hasInstantaneousCurrent = true;
          }

          if (data.plug_state !== undefined) {
            hasPlugState = true;
          }

          if (data.instantaneous_active_power !== undefined) {
            hasInstantaneousActivePower = true;
          }

          if (data.instantaneous_power_factor !== undefined) {
            hasInstantaneousPowerFactor = true;
          }

          if (data.door_open !== undefined) {
            hasDoor_open = true;
          }

          if (data.door_open2 !== undefined) {
            hasDoor_open2 = true;
          }

          if (data.gas_pressure_psi !== undefined) {
            hasgas_pressure_psi = true;
          }

          if (data.idc_intput_ma !== undefined) {
            hasidc_intput_ma = true;
          }

          if (data.co2 !== undefined && data.co2 !== null) {
            hasc02PPM = true;
          }

          if (data.CO2 !== undefined && data.CO2 !== null) {
            hasc02PPM = true;
          }

          if (data.accuracy !== undefined && data.accuracy !== null) {
            hasAccuracy = true;
          }

          if (data.leak_status !== undefined && data.leak_status !== null) {
            hasLeakStatus = true;
          }

          if (data.error_code !== undefined && data.error_code !== null) {
            hasErrorCode = true;
          }

          if (data.ambient_temp !== undefined && data.ambient_temp !== null) {
            hasAmbientTemp = true;
          }

          if (data.temperature_1 !== undefined && data.temperature_1 !== null) {
            hasTemperature_1 = true;
          }

          if (data.temperature_2 !== undefined && data.temperature_2 !== null) {
            hasTemperature_2 = true;
          }

          if (
            data.water_temp_min !== undefined &&
            data.water_temp_min !== null
          ) {
            hasWaterTempMin = true;
          }

          if (
            data.water_temp_max !== undefined &&
            data.water_temp_max !== null
          ) {
            hasWaterTempMax = true;
          }

          if (data.total_volume !== undefined && data.total_volume !== null) {
            hasTotalVolume = true;
          }

          if (
            data.battery_status !== undefined &&
            data.battery_status !== null
          ) {
            hasBatteryStatus = true;
          }

          if (data.RH !== undefined && data.RH !== null) {
            hasRH = true;
          }

          if (data.TVOC !== undefined && data.TVOC !== null) {
            hasTVOC = true;
          }

          if (data.PM1_0 !== undefined && data.PM1_0 !== null) {
            haspm1_0 = true;
          }

          if (data.conc_pm1_0 !== undefined && data.conc_pm1_0 !== null) {
            haspm1_0 = true;
          }

          if (data.conc_pm2_5 !== undefined && data.conc_pm2_5 !== null) {
            haspm2_5 = true;
          }

          if (data.conc_pm4_0 !== undefined && data.conc_pm4_0 !== null) {
            haspm4_0 = true;
          }

          if (data.conc_pm10 !== undefined && data.conc_pm10 !== null) {
            haspm10 = true;
          }

          if (data.PM4_0 !== undefined && data.PM4_0 !== null) {
            haspm4_0 = true;
          }

          if (data.PM2_5 !== undefined && data.PM2_5 !== null) {
            haspm2_5 = true;
          }

          if (data.PM10 !== undefined && data.PM10 !== null) {
            haspm10 = true;
          }

          if (data.AQI !== undefined && data.AQI !== null) {
            hasAQI = true;
          }

          if (data.temperature !== undefined && data.temperature !== null) {
            hasTemp = true;
          }
          if (data.temp !== undefined && data.temp !== null) {
            hasTemp2 = true;
          }

          if (data.humidity !== undefined && data.humidity !== null) {
            hasHumidity = true;
          }

          if (data.rh !== undefined && data.rh !== null) {
            hasHumidity = true;
          }

          if (data.light !== undefined && data.light !== null) {
            hasLight = true;
          }

          if (data.event_status !== undefined && data.event_status !== null) {
            hasEventStatus = true;
          }

          if (data.speedKmph !== undefined && data.speedKmph !== null) {
            hasSpeedKmph = true;
          }

          if (data.headingDeg !== undefined && data.headingDeg !== null) {
            hasHeadingDeg = true;
          }

          if (data.geocode !== undefined) {
            hasLocation = true;
          }

          if (data.location_resolution_method !== undefined) {
            hasLocationResolutionMethod = true;
          }

          if (data.motion !== undefined) {
            hasMotion = true;
          }

          if (data.button_pressed !== undefined) {
            hasButtonPressed = true;
          }

          if (data.HasFlowRate !== undefined) {
            hasFlowRate = true;
          }

          if (data.positiveCumulative !== undefined) {
            hasPositiveCumulative = true;
          }

          if (data.instantaneousVelocity !== undefined) {
            hasInstantaneousVelocity = true;
          }

          if (data.instantaneousFlow !== undefined) {
            hasInstantaneousFlow = true;
          }

          if (data.distance !== undefined) {
            //if not null, add to columns
            if (data.distance !== null) {
              hasDistance = true;
            }
          }

          if (data.position !== undefined) {
            hasPosition = true;
          }

          if (data.current !== undefined) {
            hasCurrent = true;
          }

          if (data.voltage !== undefined) {
            hasVoltage = true;
          }

          if (data.watt !== undefined) {
            hasWatts = true;
          }

          if (data.sos_event !== undefined) {
            hasSosEvent = true;
          }

          if (data.leak !== undefined) {
            hasLeak = true;
          }

          if (data.RH !== undefined) {
            hasRH = true;
          }

          if (data.state !== undefined) {
            hasDryContact = true;
          }

          if (data.min !== undefined) {
            hasMin = true;
          }

          if (data.max !== undefined) {
            hasMax = true;
          }

          if (data.avg !== undefined) {
            hasAvg = true;
          }

          if (data.rssi !== undefined) {
            hasRssi = true;
          }

          if (data.analog !== undefined) {
            hasAnalog = true;
          }

          if (data.button1 !== undefined) {
            hasButton1 = true;
          }

          if (data.button2 !== undefined) {
            hasButton2 = true;
          }

          if (data.button3 !== undefined) {
            hasButton3 = true;
          }

          if (data.button4 !== undefined) {
            hasButton4 = true;
          }
          if (data.temp_white !== undefined) {
            hasTempWhite = true;
          }

          if (data.pressure !== undefined) {
            hasPressure = true;
          }

          if (data.soil_temperature !== undefined) {
            hasTemperatureSoil = true;
          }

          if (data.soil_moisture !== undefined) {
            hasWaterSoil = true;
          }

          if (data.soil_conductivity !== undefined) {
            hasConductivitySoil = true;
          }

          if (data.soil_ph !== undefined) {
            hasSoilPH = true;
          }

          if (data.leaf_temperature !== undefined) {
            hasLeafTemperature = true;
          }

          if (data.leaf_moisture !== undefined) {
            hasLeafMoisture = true;
          }

          if (data.ampHourAccumulation !== undefined) {
            hasAmpHourAccumulation = true;
          }

          if (data.averageAmps !== undefined) {
            hasAverageAmps = true;
          }

          if (data.maximumAmps !== undefined) {
            hasMaximumAmps = true;
          }

          if (data.minimumAmps !== undefined) {
            hasMinimumAmps = true;
          }

          if (data.capacitorVoltage !== undefined) {
            hasCapacitorVoltage = true;
          }

          if (data.temperatureCelsius !== undefined) {
            hasTemperatureCelsius = true;
          }

          if (data.noise !== undefined) {
            hasSound = true;
          }

          if (data.mass_conc_pm1_0 !== undefined) {
            hasMasspm1_0 = true;
          }

          if (data.mass_conc_pm2_5 !== undefined) {
            hasMasspm2_5 = true;
          }

          if (data.mass_conc_pm4_0 !== undefined) {
            hasMasspm4_0 = true;
          }

          if (data.mass_conc_pm10 !== undefined) {
            hasMasspm10 = true;
          }

          if (data.voc_index !== undefined) {
            hasvoc_index = true;
          }

          if (data.movement !== undefined) {
            //check the device profile for 5inone and check if it has showMotion set to true
            if (data.movement !== null) {
              if (deviceProfile) {
                //might be an object, check
                if (typeof deviceProfile === "string") {
                  let deviceProfileParsed = JSON.parse(deviceProfile);
                  if (
                    deviceProfileParsed.fiveInOne &&
                    deviceProfileParsed.fiveInOne.showMotion
                  ) {
                    hasMovementCount = true;
                  }
                } else {
                  hasMovementCount = false;
                }
              } else {
                hasMovementCount = false;
              }
            }
          }

          if (data.mold_index !== undefined) {
            //check the device profile for 5inone and check if it has showMoldIndex set to true
            if (data.mold_index !== null) {
              if (deviceProfile) {
                if (typeof deviceProfile === "string") {
                  let deviceProfileParsed = JSON.parse(deviceProfile);
                  if (
                    deviceProfileParsed.fiveInOne &&
                    deviceProfileParsed.fiveInOne.showMoldIndex
                  ) {
                    hasMoldIndex = true;
                  }
                } else {
                  hasMoldIndex = false;
                }
              } else {
                hasMovementCount = false;
              }
            }
          }
        }

        if (hasPlugState) {
          tempColumns.push({
            name: "data",
            label: "Plug State",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                //get plug_state from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let plug_state = jsonData.plug_state;

                if (plug_state === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{plug_state ? "On" : "Off"}</Typography>;
              },
            },
          });
        }

        if (hasInstantaneousActivePower) {
          tempColumns.push({
            name: "data",
            label: "Active Power",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                //get instantaneous_active_power from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let instantaneous_active_power =
                  jsonData.instantaneous_active_power;
                if (instantaneous_active_power === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{instantaneous_active_power}</Typography>;
              },
            },
          });
        }

        if (hasInstantaneousPowerFactor) {
          tempColumns.push({
            name: "data",
            label: "Active Power Factor",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                //get instantaneous_power_factor from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let instantaneous_power_factor =
                  jsonData.instantaneous_power_factor;
                if (instantaneous_power_factor === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{instantaneous_power_factor}</Typography>;
              },
            },
          });
        }

        if (hasEnergyLastHour) {
          tempColumns.push({
            name: "data",
            label: "Energy Last Hour",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                //get energy_of_last_hour from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let energy_of_last_hour = jsonData.energy_of_last_hour;
                if (energy_of_last_hour === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{energy_of_last_hour}</Typography>;
              },
            },
          });
        }

        if (hasTotalEnergy) {
          tempColumns.push({
            name: "data",
            label: "Total Energy",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                //get total_energy from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let total_energy = jsonData.total_energy;
                if (total_energy === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{total_energy}</Typography>;
              },
            },
          });
        }

        if (hasInstantaneousCurrent) {
          tempColumns.push({
            name: "data",
            label: "Active Current",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                //get instantaneous_current from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let instantaneous_current = jsonData.instantaneous_current;
                if (instantaneous_current === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{instantaneous_current}</Typography>;
              },
            },
          });
        }

        if (hasInstantaneousVoltage) {
          tempColumns.push({
            name: "data",
            label: "Active Voltage",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                //get instantaneous_voltage from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let instantaneous_voltage = jsonData.instantaneous_voltage;
                if (instantaneous_voltage === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{instantaneous_voltage}</Typography>;
              },
            },
          });
        }

        if (hasDoor_open) {
          tempColumns.push({
            name: "data",
            label: "Door Open",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                //get door_open from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let door_open = jsonData.door_open;
                if (door_open === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return (
                  <Typography>{door_open === 1 ? "Yes" : "No"}</Typography>
                );
              },
            },
          });
        }

        if (hasDoor_open2) {
          tempColumns.push({
            name: "data",
            label: "Door 2 Open",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                //get door_open from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let door_open2 = jsonData.door_open2;
                if (door_open2 === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return (
                  <Typography>{door_open2 === 1 ? "Yes" : "No"}</Typography>
                );
              },
            },
          });
        }

        if (hasAmpHourAccumulation) {
          tempColumns.push({
            name: "data",
            label: "Amp Hour Accumulation",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                //get temperature from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let amp_hour_accumulation = jsonData.amp_hour_accumulation;
                if (amp_hour_accumulation === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{amp_hour_accumulation} Ah</Typography>;
              },
            },
          });
        }

        if (hasAverageAmps) {
          tempColumns.push({
            name: "data",
            label: "Average Amps",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                //get temperature from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let average_amps = jsonData.average_amps;
                if (average_amps === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{average_amps} A</Typography>;
              },
            },
          });
        }

        if (hasMaximumAmps) {
          tempColumns.push({
            name: "data",
            label: "Maximum Amps",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                //get temperature from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let maximum_amps = jsonData.maximum_amps;
                if (maximum_amps === undefined) {
                  return <Typography>N/A</Typography>;
                }

                return <Typography>{maximum_amps} A</Typography>;
              },
            },
          });
        }

        if (hasMinimumAmps) {
          tempColumns.push({
            name: "data",
            label: "Minimum Amps",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                //get temperature from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let minimum_amps = jsonData.minimum_amps;
                if (minimum_amps === undefined) {
                  return <Typography>N/A</Typography>;
                }

                return <Typography>{minimum_amps} A</Typography>;
              },
            },
          });
        }

        if (hasCapacitorVoltage) {
          tempColumns.push({
            name: "data",
            label: "Capacitor Voltage",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                //get temperature from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let capacitor_voltage = jsonData.capacitor_voltage;
                if (capacitor_voltage === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{capacitor_voltage} V</Typography>;
              },
            },
          });
        }

        if (hasTemperatureCelsius) {
          tempColumns.push({
            name: "data",
            label: "Temperature Celsius",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                //get temperature from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let temperature_celsius = jsonData.temperature_celsius;
                if (temperature_celsius === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return (
                  <Typography>
                    {formatTemperature(temperature_celsius)}
                  </Typography>
                );
              },
            },
          });
        }

        if (hasLeafMoisture) {
          tempColumns.push({
            name: "data",
            label: "Leaf Moisture",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                //get temperature from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let leaf_moisture = jsonData.leaf_moisture;
                if (leaf_moisture === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{leaf_moisture}%</Typography>;
              },
            },
          });
        }

        if (hasLeafTemperature) {
          tempColumns.push({
            name: "data",
            label: "Leaf Temperature",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                //get temperature from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let leaf_temperature = jsonData.leaf_temperature;
                if (leaf_temperature === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return (
                  <Typography>{formatTemperature(leaf_temperature)}</Typography>
                );
              },
            },
          });
        }

        if (hasTemperature_1) {
          tempColumns.push({
            name: "data",
            label: "Temperature 1",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                //get temperature from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let temperature_1 = jsonData.temperature_1;
                if (temperature_1 === undefined) {
                  return <Typography>N/A</Typography>;
                }

                return (
                  <Typography>{formatTemperature(temperature_1)}</Typography>
                );
              },
            },
          });
        }

        if (hasgas_pressure_psi) {
          tempColumns.push({
            name: "data",
            label: "Gas Pressure PSI",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                //get temperature from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let gas_pressure_psi = jsonData.gas_pressure_psi;
                if (gas_pressure_psi === undefined) {
                  return <Typography>N/A</Typography>;
                } else {
                  return <Typography>{gas_pressure_psi} PSI</Typography>;
                }
              },
            },
          });
        }

        if (hasidc_intput_ma) {
          tempColumns.push({
            name: "data",
            label: "Transducer mA",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                //get temperature from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let idc_intput_ma = jsonData.idc_intput_ma;
                if (idc_intput_ma === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{idc_intput_ma} mA</Typography>;
              },
            },
          });
        }

        if (hasTemperature_2) {
          tempColumns.push({
            name: "data",
            label: "Temperature 2",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                //get temperature from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let temperature_2 = jsonData.temperature_2;
                if (temperature_2 === undefined) {
                  return <Typography>N/A</Typography>;
                }

                return (
                  <Typography>{formatTemperature(temperature_2)}</Typography>
                );
              },
            },
          });
        }

        if (hasSoilPH) {
          tempColumns.push({
            name: "data",
            label: "Soil PH",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                //get temperature from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let soil_ph = jsonData.soil_ph;
                if (soil_ph === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{soil_ph}</Typography>;
              },
            },
          });
        }

        if (hasTemperatureSoil) {
          tempColumns.push({
            name: "data",
            label: "Soil Temperature",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                //get temperature from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let temperature = jsonData.soil_temperature;
                if (temperature === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return (
                  <Typography>{formatTemperature(temperature)}</Typography>
                );
              },
            },
          });
        }

        if (hasWaterSoil) {
          tempColumns.push({
            name: "data",
            label: "Soil Moisture",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                //get temperature from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let water = jsonData.soil_moisture;
                if (water === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{water}%</Typography>;
              },
            },
          });
        }

        if (hasConductivitySoil) {
          tempColumns.push({
            name: "data",
            label: "Soil Conductivity",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                //get temperature from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let conductivity = jsonData.soil_conductivity;
                if (conductivity === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{conductivity}</Typography>;
              },
            },
          });
        }

        //add columns
        if (hasLocation) {
          tempColumns.push({
            //location
            name: "data",
            label: t("devices.last_location"),
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                //get geocode from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let geocode = jsonData.geocode;
                if (geocode === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{geocode}</Typography>;
              },
            },
          });
        }

        if (hasLeak) {
          tempColumns.push({
            name: "data",
            label: "Leak",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                //get leak from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let leak = jsonData.leak;
                if (leak === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{leak ? "Yes" : "No"}</Typography>;
              },
            },
          });
        }

        if (hasLocationResolutionMethod) {
          tempColumns.push({
            //location resolution method
            name: "data",
            label: t("devices.location_resolution_method"),
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                //get location_resolution_method from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let location_resolution_method =
                  jsonData.location_resolution_method;
                if (location_resolution_method === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{location_resolution_method}</Typography>;
              },
            },
          });
        }

        if (hasc02PPM) {
          tempColumns.push({
            name: "data",
            label: t("devices.c02_ppm"),
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                if (!value) return <Typography>N/A</Typography>;
                //get temperature from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let c02PPM = jsonData.co2 || jsonData.CO2;

                //if its an array, use the first value
                if (Array.isArray(c02PPM)) {
                  c02PPM = c02PPM[0];
                }
                if (c02PPM === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{c02PPM} ppm</Typography>;
              },
            },
          });
        }

        if (hasLeakStatus) {
          tempColumns.push({
            name: "data",
            label: "Leak Status",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                if (!value) return <Typography>N/A</Typography>;
                //get leak_status from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let leak_status = jsonData.leak_status;
                if (leak_status === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return (
                  <Typography>
                    {leak_status === "NoLeak" ? "No Leak" : leak_status}
                  </Typography>
                );
              },
            },
          });
        }

        if (hasTemp) {
          tempColumns.push({
            name: "data",
            label: t("devices.temperature"),
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                if (!value) return <Typography>N/A</Typography>;
                //get temperature from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let temperature = jsonData.temperature;
                if (temperature === undefined) {
                  return <Typography>N/A</Typography>;
                }

                return (
                  <Typography>{formatTemperature(temperature)}</Typography>
                );
              },
            },
          });
        }

        if (hasTemp2) {
          tempColumns.push({
            name: "data",
            label: "Temperature",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                if (!value) return <Typography>N/A</Typography>;
                //get temperature from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let temperature = jsonData.temp;
                if (temperature === undefined) {
                  return <Typography>N/A</Typography>;
                }

                return (
                  <Typography>{formatTemperature(temperature)}</Typography>
                );
              },
            },
          });
        }

        if (hasHumidity) {
          tempColumns.push({
            name: "data",
            label: t("devices.humidity"),
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                if (!value) return <Typography>N/A</Typography>;
                //get temperature from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let humidity = jsonData.humidity || jsonData.rh;
                if (humidity === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return (
                  <Typography>{humidity && humidity.toFixed(1)}%</Typography>
                );
              },
            },
          });
        }

        if (hasLight) {
          tempColumns.push({
            name: "data",
            label: t("devices.light"),
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                if (!value) return <Typography>N/A</Typography>;
                //get light from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let light = jsonData.light;
                if (light === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{light}</Typography>;
              },
            },
          });
        }

        if (hasEventStatus) {
          tempColumns.push({
            name: "data",
            label: t("devices.event_status"),
            options: {
              filter: false,
              sort: false,

              customBodyRender: (value, tableMeta, updateValue) => {
                if (!value) return <Typography>N/A</Typography>;
                //get event_status from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let event_status = jsonData.event_status;
                if (event_status === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return (
                  <Typography>
                    {event_status === "" ? "No Event" : event_status}
                  </Typography>
                );
              },
            },
          });
        }

        if (hasPressure) {
          tempColumns.push({
            name: "data",
            label: t("devices.pressure"),
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value) => {
                // Remove unused parameters 'tableMeta' and 'updateValue'
                //get pressure from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let pressure = jsonData.pressure;

                if (pressure === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{pressure} hPa</Typography>;
              },
            },
          });
        }

        if (hasSound) {
          tempColumns.push({
            name: "data",
            label: "Sound",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value) => {
                // Remove unused parameters 'tableMeta' and 'updateValue'
                //get sound from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let sound = jsonData.noise;

                if (sound === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{sound} dB</Typography>;
              },
            },
          });
        }

        if (hasAccuracy) {
          tempColumns.push({
            name: "data",
            label: "Accuracy",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                if (!value) return <Typography>N/A</Typography>;
                //get accuracy from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let accuracy = jsonData.accuracy;
                if (accuracy === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{accuracy}</Typography>;
              },
            },
          });
        }

        if (hasSpeedKmph) {
          tempColumns.push({
            name: "data",
            label: t("devices.speed"),
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                if (!value) return <Typography>N/A</Typography>;
                //get speedKmph from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let speedKmph = jsonData.speedKmph;

                let unit = localStorage.getItem("speed_unit") || "kmph";

                if (unit === "mph") {
                  speedKmph = speedKmph * 0.621371;
                }

                //round speed to 2 decimal places
                speedKmph = Math.round(speedKmph * 100) / 100;

                if (speedKmph === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{speedKmph + " " + unit}</Typography>;
              },
            },
          });
        }

        if (hasHeadingDeg) {
          tempColumns.push({
            name: "data",
            label: t("devices.heading"),
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                if (!value) return <Typography>N/A</Typography>;
                //get headingDeg from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let headingDeg = jsonData.headingDeg;
                if (headingDeg === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{headingDeg}</Typography>;
              },
            },
          });
        }

        if (hasMotion) {
          tempColumns.push({
            name: "data",
            label: t("devices.motion"),
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                if (!value) return <Typography>N/A</Typography>;
                //get motion from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let motion = jsonData.motion;
                if (motion === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{motion ? "Yes" : "No"}</Typography>;
              },
            },
          });
        }

        if (hasButtonPressed) {
          tempColumns.push({
            name: "data",
            label: t("devices.button_pressed"),
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                if (!value) return <Typography>N/A</Typography>;
                //get button_pressed from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let button_pressed = jsonData.button_pressed;
                if (button_pressed === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{button_pressed ? "Yes" : "No"}</Typography>;
              },
            },
          });
        }

        if (hasFlowRate) {
          tempColumns.push({
            name: "data",
            label: t("Has Flow?"),
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                //HasFlowRate true or false, 1 or 0 display Yes or No

                if (!value) return <Typography>N/A</Typography>;
                //get HasFlowRate from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let HasFlowRate = jsonData.HasFlowRate;
                if (HasFlowRate === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{HasFlowRate ? "Yes" : "No"}</Typography>;
              },
            },
          });
        }

        if (hasInstantaneousFlow) {
          tempColumns.push({
            name: "data",
            label: "Flow",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value) => {
                // Remove unused parameters 'tableMeta' and 'updateValue'
                //get instantaneousFlow from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let instantaneousFlow = jsonData.instantaneousFlow;
                if (instantaneousFlow === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{instantaneousFlow}</Typography>;
              },
            },
          });
        }

        if (hasInstantaneousVelocity) {
          tempColumns.push({
            name: "data",
            label: "Velocity",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                //get instantaneousVelocity from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let instantaneousVelocity = jsonData.instantaneousVelocity;
                if (instantaneousVelocity === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{instantaneousVelocity}</Typography>;
              },
            },
          });
        }

        if (hasPositiveCumulative) {
          tempColumns.push({
            name: "data",
            label: "Total Usage",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                //get positiveCumulative from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let positiveCumulative = jsonData.positiveCumulative;
                if (positiveCumulative === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{positiveCumulative}</Typography>;
              },
            },
          });
        }

        if (hasDistance) {
          tempColumns.push({
            name: "data",
            label: t("devices.distance"),
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value) => {
                // Remove unused parameters 'tableMeta' and 'updateValue'
                //get distance from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let distance = jsonData.distance;
                if (distance === undefined || distance === null) {
                  return <Typography>No Measurement</Typography>;
                }

                return <Typography>{distance}mm</Typography>;
              },
            },
          });
        }

        if (hasPosition) {
          tempColumns.push({
            name: "data",
            label: t("devices.position"),
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value) => {
                // Remove unused parameters 'tableMeta' and 'updateValue'
                //get position from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let position = jsonData.position;

                if (position === undefined) {
                  return <Typography>N/A</Typography>;
                }

                return <Typography>{position}</Typography>;
              },
            },
          });
        }

        if (hasCurrent) {
          tempColumns.push({
            name: "data",
            label: t("devices.current"),
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value) => {
                // Remove unused parameters 'tableMeta' and 'updateValue'
                //get current from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let current = jsonData.current;
                if (current === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{current}A</Typography>;
              },
            },
          });
        }

        if (hasVoltage) {
          tempColumns.push({
            name: "data",
            label: t("devices.voltage"),
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value) => {
                // Remove unused parameters 'tableMeta' and 'updateValue'
                //get voltage from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let voltage = jsonData.voltage;
                if (voltage === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{voltage}V</Typography>;
              },
            },
          });
        }

        if (hasWatts) {
          tempColumns.push({
            name: "data",
            label: t("devices.watts"),
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value) => {
                // Remove unused parameters 'tableMeta' and 'updateValue'
                //get watts from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let watts = jsonData.watt;
                if (watts === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{watts}W</Typography>;
              },
            },
          });
        }

        if (hasSosEvent) {
          tempColumns.push({
            name: "data",
            label: t("devices.sos_event"),
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value) => {
                // Remove unused parameters 'tableMeta' and 'updateValue'
                //get sos_event from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let sos_event = jsonData.sos_event;
                if (sos_event === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{sos_event ? "Yes" : "No"}</Typography>;
              },
            },
          });
        }

        if (hasRH) {
          tempColumns.push({
            name: "data",
            label: "Humidity",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value) => {
                // Remove unused parameters 'tableMeta' and 'updateValue'
                //get humidity from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let humidity = jsonData.RH;
                if (humidity === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return (
                  <Typography>
                    {humidity && humidity !== null
                      ? humidity.toFixed(1)
                      : "N/A"}
                    %
                  </Typography>
                );
              },
            },
          });
        }

        if (hasTVOC) {
          tempColumns.push({
            name: "data",
            label: "TVOC",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value) => {
                // Remove unused parameters 'tableMeta' and 'updateValue'
                //get TVOC from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let tvoc = jsonData.TVOC;

                //if array, return first
                if (Array.isArray(tvoc)) {
                  tvoc = tvoc[0];
                }

                if (tvoc === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{tvoc}</Typography>;
              },
            },
          });
        }

        if (hasvoc_index) {
          tempColumns.push({
            name: "data",
            label: "VOC Index",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value) => {
                // Remove unused parameters 'tableMeta' and 'updateValue'
                //get VOC Index from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let voc_index = jsonData.voc_index;

                if (voc_index === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{voc_index}</Typography>;
              },
            },
          });
        }

        if (haspm1_0) {
          tempColumns.push({
            name: "data",
            label: "PM1.0 Conc",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value) => {
                // Remove unused parameters 'tableMeta' and 'updateValue'
                //get PM1.0 from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let pm1_0 = jsonData.PM1_0 || jsonData.conc_pm1_0;

                //if array, use first value
                if (Array.isArray(pm1_0)) {
                  pm1_0 = pm1_0[0];
                }

                if (pm1_0 === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{pm1_0} µg/m³</Typography>;
              },
            },
          });
        }

        if (haspm2_5) {
          tempColumns.push({
            name: "data",
            label: "PM2.5 Conc",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value) => {
                // Remove unused parameters 'tableMeta' and 'updateValue'
                //get PM2.5 from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let pm2_5 = jsonData.PM2_5 || jsonData.conc_pm2_5;

                //if array, use first value
                if (Array.isArray(pm2_5)) {
                  pm2_5 = pm2_5[0];
                }

                if (pm2_5 === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{pm2_5} µg/m³</Typography>;
              },
            },
          });
        }

        if (haspm4_0) {
          tempColumns.push({
            name: "data",
            label: "PM4.0 Conc",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value) => {
                // Remove unused parameters 'tableMeta' and 'updateValue'
                //get PM4.0 from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let pm4_0 = jsonData.PM4_0 || jsonData.conc_pm4_0;

                //if array, use first value
                if (Array.isArray(pm4_0)) {
                  pm4_0 = pm4_0[0];
                }

                if (pm4_0 === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{pm4_0} µg/m³</Typography>;
              },
            },
          });
        }

        if (haspm10) {
          tempColumns.push({
            name: "data",
            label: "PM10 Conc",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value) => {
                // Remove unused parameters 'tableMeta' and 'updateValue'
                //get PM10 from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let pm10 = jsonData.PM10 || jsonData.conc_pm10;

                //if array, return first
                if (Array.isArray(pm10)) {
                  pm10 = pm10[0];
                }

                if (pm10 === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{pm10} µg/m³</Typography>;
              },
            },
          });
        }

        if (hasMasspm1_0) {
          tempColumns.push({
            name: "data",
            label: "PM1.0 Mass",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value) => {
                // Remove unused parameters 'tableMeta' and 'updateValue'
                //get PM1.0 from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let masspm1_0 = jsonData.mass_conc_pm1_0;

                if (masspm1_0 === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{masspm1_0} µg/m³</Typography>;
              },
            },
          });
        }

        if (hasMasspm2_5) {
          tempColumns.push({
            name: "data",
            label: "PM2.5 Mass",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value) => {
                // Remove unused parameters 'tableMeta' and 'updateValue'
                //get PM2.5 from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let masspm2_5 = jsonData.mass_conc_pm2_5;

                if (masspm2_5 === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{masspm2_5} µg/m³</Typography>;
              },
            },
          });
        }

        if (hasMasspm4_0) {
          tempColumns.push({
            name: "data",
            label: "PM4.0 Mass",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value) => {
                // Remove unused parameters 'tableMeta' and 'updateValue'
                //get PM4.0 from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let masspm4_0 = jsonData.mass_conc_pm4_0;

                if (masspm4_0 === undefined) {
                  return <Typography>N/A</Typography>;
                }

                return <Typography>{masspm4_0} µg/m³</Typography>;
              },
            },
          });
        }

        if (hasMasspm10) {
          tempColumns.push({
            name: "data",
            label: "PM10 Mass",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value) => {
                // Remove unused parameters 'tableMeta' and 'updateValue'
                //get PM10 from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let masspm10 = jsonData.mass_conc_pm10;

                if (masspm10 === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{masspm10} µg/m³</Typography>;
              },
            },
          });
        }

        if (hasAQI) {
          tempColumns.push({
            name: "data",
            label: "AQI",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value) => {
                // Remove unused parameters 'tableMeta' and 'updateValue'
                //get AQI from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let aqi = jsonData.AQI;

                //if array, return first value
                if (Array.isArray(aqi)) {
                  aqi = aqi[0];
                }

                if (aqi === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{aqi}</Typography>;
              },
            },
          });
        }

        if (hasDryContact) {
          tempColumns.push({
            name: "data",
            label: props.state ? props.state : "Contact",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value) => {
                // Remove unused parameters 'tableMeta' and 'updateValue'
                //get state from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let state = jsonData.state;

                if (state === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{state ? "Yes" : "No"}</Typography>;
              },
            },
          });
        }

        if (hasMin) {
          tempColumns.push({
            name: "data",
            label: "Min",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value) => {
                // Remove unused parameters 'tableMeta' and 'updateValue'
                //get min from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let min = jsonData.min;

                if (min === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{min}</Typography>;
              },
            },
          });
        }

        if (hasMax) {
          tempColumns.push({
            name: "data",
            label: "Max",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value) => {
                // Remove unused parameters 'tableMeta' and 'updateValue'
                //get max from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let max = jsonData.max;

                if (max === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{max}</Typography>;
              },
            },
          });
        }

        if (hasAvg) {
          tempColumns.push({
            name: "data",
            label: "Avg",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value) => {
                // Remove unused parameters 'tableMeta' and 'updateValue'
                //get avg from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let avg = jsonData.avg;

                if (avg === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{avg}</Typography>;
              },
            },
          });
        }

        if (hasAnalog) {
          tempColumns.push({
            name: "data",
            label: "Analog",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value) => {
                // Remove unused parameters 'tableMeta' and 'updateValue'
                //get analog from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let analog = jsonData.analog;

                if (analog === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{analog}</Typography>;
              },
            },
          });
        }

        if (hasRssi) {
          tempColumns.push({
            name: "data",
            label: "RSSI",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value) => {
                // Remove unused parameters 'tableMeta' and 'updateValue'
                //get rssi from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let rssi = jsonData.rssi;

                if (rssi === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{rssi} dBm</Typography>;
              },
            },
          });
        }

        if (hasAmbientTemp) {
          tempColumns.push({
            name: "data",
            label: "Ambient Temp",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value) => {
                // Remove unused parameters 'tableMeta' and 'updateValue'
                //get ambient_temp from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let ambient_temp = jsonData.ambient_temp;

                if (ambient_temp === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{ambient_temp}</Typography>;
              },
            },
          });
        }

        if (hasWaterTempMin) {
          tempColumns.push({
            name: "data",
            label: "Water Temp Min",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value) => {
                // Remove unused parameters 'tableMeta' and 'updateValue'
                //get water_temp_min from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let water_temp_min = jsonData.water_temp_min;

                if (water_temp_min === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{water_temp_min}</Typography>;
              },
            },
          });
        }

        if (hasWaterTempMax) {
          tempColumns.push({
            name: "data",
            label: "Water Temp Max",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value) => {
                // Remove unused parameters 'tableMeta' and 'updateValue'
                //get water_temp_max from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let water_temp_max = jsonData.water_temp_max;

                if (water_temp_max === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{water_temp_max}</Typography>;
              },
            },
          });
        }

        if (hasTotalVolume) {
          tempColumns.push({
            name: "data",
            label: "Total Volume",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value) => {
                // Remove unused parameters 'tableMeta' and 'updateValue'
                //get total_volume from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let total_volume = jsonData.total_volume;

                if (total_volume === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{total_volume}</Typography>;
              },
            },
          });
        }

        if (hasErrorCode) {
          tempColumns.push({
            name: "data",
            label: "Error Code",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value) => {
                // Remove unused parameters 'tableMeta' and 'updateValue'
                //get error_code from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let error_code = jsonData.error_code;

                if (error_code === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{error_code}</Typography>;
              },
            },
          });
        }

        if (hasBatteryStatus) {
          tempColumns.push({
            name: "data",
            label: "Battery Status",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value) => {
                // Remove unused parameters 'tableMeta' and 'updateValue'
                //get battery_status from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let battery_status = jsonData.battery_status;

                if (battery_status === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{battery_status}</Typography>;
              },
            },
          });
        }

        if (hasButton1) {
          tempColumns.push({
            name: "data",
            label: button1Label,
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value) => {
                // Remove unused parameters 'tableMeta' and 'updateValue'
                //get button1 from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let button1 = jsonData.button1 === 1 ? "Yes" : "No";

                if (button1 === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return (
                  //show tick if button1 is 1 and cross if button1 is 0

                  button1 === "Yes" ? (
                    <Check style={{ color: "green" }} />
                  ) : (
                    <Close style={{ color: "red" }} />
                  )
                );
              },
            },
          });
        }

        if (hasButton2) {
          tempColumns.push({
            name: "data",
            label: button2Label,
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value) => {
                // Remove unused parameters 'tableMeta' and 'updateValue'
                //get button2 from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let button2 = jsonData.button2 === 1 ? "Yes" : "No";

                if (button2 === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return (
                  //show tick if button1 is 2 and cross if button2 is 0

                  button2 === "Yes" ? (
                    <Check style={{ color: "green" }} />
                  ) : (
                    <Close style={{ color: "red" }} />
                  )
                );
              },
            },
          });
        }

        if (hasButton3) {
          tempColumns.push({
            name: "data",
            label: button3Label,
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value) => {
                // Remove unused parameters 'tableMeta' and 'updateValue'
                //get button3 from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let button3 = jsonData.button3 === 1 ? "Yes" : "No";

                if (button3 === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return (
                  //show tick if button1 is 2 and cross if button2 is 0

                  button3 === "Yes" ? (
                    <Check style={{ color: "green" }} />
                  ) : (
                    <Close style={{ color: "red" }} />
                  )
                );
              },
            },
          });
        }

        if (hasButton4) {
          tempColumns.push({
            name: "data",
            label: button4Label,
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value) => {
                // Remove unused parameters 'tableMeta' and 'updateValue'
                //get button4 from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let button4 = jsonData.button4 === 1 ? "Yes" : "No";

                if (button4 === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return (
                  //show tick if button1 is 2 and cross if button2 is 0

                  button4 === "Yes" ? (
                    <Check style={{ color: "green" }} />
                  ) : (
                    <Close style={{ color: "red" }} />
                  )
                );
              },
            },
          });
        }

        if (hasTempWhite) {
          tempColumns.push({
            name: "data",
            label: "Temperature Red",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value) => {
                // Remove unused parameters 'tableMeta' and 'updateValue'
                //get temp_red from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let temp_red = jsonData.temp_red;

                if (temp_red === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{formatTemperature(temp_red)}</Typography>;
              },
            },
          });

          tempColumns.push({
            name: "data",
            label: "Temperature Black",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value) => {
                // Remove unused parameters 'tableMeta' and 'updateValue'
                //get temp_black from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let temp_black = jsonData.temp_black;

                if (temp_black === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{formatTemperature(temp_black)}</Typography>;
              },
            },
          });

          //add columns for temp_white, temp_black and temp_red
          tempColumns.push({
            name: "data",
            label: "Temperature White",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value) => {
                // Remove unused parameters 'tableMeta' and 'updateValue'
                //get temp_white from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let temp_white = jsonData.temp_white;

                if (temp_white === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{formatTemperature(temp_white)}</Typography>;
              },
            },
          });
        }

        if (hasMovementCount) {
          tempColumns.push({
            name: "data",
            label: "Movement Count",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value) => {
                //get movement count from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let movementCount = jsonData.movement;

                if (movementCount === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{movementCount}</Typography>;
              },
            },
          });
        }

        if (hasMoldIndex) {
          tempColumns.push({
            name: "data",
            label: "Mold Index",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value) => {
                //get mold index from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let moldIndex = jsonData.mold_index;

                if (moldIndex === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{moldIndex}</Typography>;
              },
            },
          });
        }

        if (
          device &&
          device.device_type &&
          device.device_type !== 19 &&
          device.device_type !== 27 &&
          device.device_type !== 23 &&
          device.device_type !== 28 &&
          device.device_type !== 33 &&
          device.device_type !== 43 &&
          device.device_type !== 44 &&
          device.device_type !== 45
        ) {
          tempColumns.push({
            //battery
            name: "data",
            label: t("common.battery"),
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value) => {
                // Remove unused parameters 'tableMeta' and 'updateValue'
                let battery = 0;
                //get battery from data object
                try {
                  let jsonData = value;
                  jsonData = JSON.parse(jsonData);
                  battery =
                    jsonData.voltageMv || jsonData.batteryMv || jsonData.bat;
                } catch (error) {}

                if (battery === 0) {
                  return <Typography>N/A</Typography>;
                }

                //draw a progress bar with the value and color it red if below 20% and yellow if below 50% and green if above 50%
                return (
                  <Typography>
                    {convertMvToPercent(
                      battery,
                      device && device.device_brand ? device.device_brand : null
                    )}
                  </Typography>
                );
              },
            },
          });
        }

        //update columns
        setColumns(tempColumns);
      }
    }
  }, [
    deviceHistory,
    device,
    props.state,
    t,
    deviceProfile,
    button1Label,
    button2Label,
    button3Label,
    button4Label,
  ]);

  const isOwner = () => {
    if (
      user &&
      selectedOrganisation &&
      user.id === selectedOrganisation.owner_id
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <DataTableBase
        title={"Frame History"}
        columns={columns}
        data={deviceHistory ? deviceHistory : []}
        rowsPerPage={10}
        sortOrder="time_created"
        sortDirection="desc"
        selectableRows={
          isOwner() ? (sharingShown ? "none" : "multiple") : "none"
        }
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        actions={
          sharingShown
            ? []
            : [
                {
                  name: "Delete",
                  icon: "Delete",
                  action: () => {
                    setDeleteFramesDialogOpen(true);
                  },
                },
              ]
        }
      />
      <DeleteFramesDialog
        open={deleteFramesDialogOpen}
        selectedRows={selectedRows}
        handleClose={() => {
          removeSelectedFrames(selectedRows);
          setSelectedRows([]);
          setDeleteFramesDialogOpen(false);
        }}
      />
    </>
  );
}
