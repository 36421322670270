import { Grid } from "@mui/material";
import React from "react";
import LastSeen from "../Cards/LastSeen";
import BatteryStatus from "../Cards/BatteryStatus";
import LastAlert from "../Cards/LastAlert";
import TemperatureGuague from "../Guages/Temperature";
import DeviceActionLogs from "../Cards/Alerts";
import FrameList from "../FrameList";
import TemperatureGraph from "../Graphs/Temperature";
import GraphBase from "../Graphs/GraphBase";
import HumidityGuague from "../Guages/Humidity";

export default function DraginoLlms01(props) {
  let { isShare } = props;

  return (
    <>
      <Grid
        container
        spacing={1}
        alignItems={"stretch"}
        alignContent={"stretch"}
        sx={{ mt: 1 }}
      >
        <Grid item xs={12} md={!isShare ? 4 : 6} sx={{ mb: 1 }}>
          <LastSeen />
        </Grid>
        <Grid item xs={12} md={!isShare ? 4 : 6} sx={{ mb: 1 }}>
          <BatteryStatus />
        </Grid>
        {!isShare && (
          <Grid item xs={12} md={4} sx={{ mb: 1 }}>
            <LastAlert />
          </Grid>
        )}

        <Grid item xs={12} md={3} sx={{ mb: 1 }}>
          <TemperatureGuague
            temperatureValue="leaf_temperature"
            title="Leaf Temperature"
          />
        </Grid>
        <Grid item xs={12} md={9} sx={{ mb: 1 }}>
          <TemperatureGraph
            temperatureValue="leaf_temperature"
            title="Leaf Temperature"
            aspect={5}
          />
        </Grid>

        <Grid item xs={12} md={3} sx={{ mb: 1 }}>
          <HumidityGuague humidityValue="leaf_moisture" title="Leaf Moisture" />
        </Grid>

        <Grid item xs={12} md={9} sx={{ mb: 1 }}>
          <GraphBase
            value="leaf_moisture"
            title="Leaf Moisture"
            unit="%"
            min="0"
            max="100"
            aspect={5}
          />
        </Grid>

        <Grid item xs={12} md={isShare ? 12 : 6} sx={{ mb: 1 }}>
          <FrameList />
        </Grid>

        {!isShare && (
          <>
            <Grid item xs={12} md={6}>
              <DeviceActionLogs />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}
