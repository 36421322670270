import React from "react";

import DeviceMetric from "./DeviceMetric";
import { useSelector } from "react-redux";

export default function MoldIndex() {
  const deviceHistory = useSelector((state) => state.deviceHistory);
  const themeMode = useSelector((state) => state.themeMode);

  let colour = themeMode === "light" ? "black" : "white";

  //get the last deviceHistory object, and check the mold_index value, if its Low Risk, set it to green, if its Moderate Risk, set it to yellow, if its High Risk, set it to red
  let moldIndex = "";

  if (deviceHistory && deviceHistory.length > 0) {
    let lastData = deviceHistory[deviceHistory.length - 1];
    let jsonData = JSON.parse(lastData.data);
    moldIndex = jsonData.mold_index;
  }

  if (moldIndex === "Low Risk") {
    moldIndex = "Low Risk";
  }

  if (moldIndex === "Moderate Risk") {
    moldIndex = "Moderate Risk";
  }

  if (moldIndex === "High Risk") {
    moldIndex = "High Risk";
  }

  colour =
    moldIndex === "Low Risk"
      ? "green"
      : moldIndex === "Moderate Risk"
      ? "#FFA500"
      : moldIndex
      ? "red"
      : themeMode === "light"
      ? "black"
      : "white";

  return (
    <DeviceMetric
      title="Mold Risk"
      value="mold_index"
      icon="virus"
      iconSize="6x"
      aspect={10}
      metricWidth={3}
      graphWidth={12}
      unit=""
      textsize="h3"
      valueColor={colour}
    />
  );
}
