import { useTheme } from "@emotion/react";
import React, { useEffect } from "react";
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";
import { useSelector } from "react-redux";
import { formatDate, formatDateOnly } from "../../Functions/formatDate";
import { Card, CardHeader } from "@mui/material";

export default function MotionCountGraph(props) {
  const [motionCountHistory, setMotionCountHistory] = React.useState([]);

  const deviceHistory = useSelector((state) => state.deviceHistory);
  const theme = useTheme();

  const { value } = props;

  useEffect(() => {
    if (!deviceHistory || deviceHistory.length === 0) {
      return;
    }

    try {
      //grab all data objects from deviceHistory, parse them, and add them to totalVolumeHistory
      let tempHistory = [];
      deviceHistory.forEach((dataObject) => {
        let jsonData = dataObject.data;
        jsonData = JSON.parse(jsonData);
        let time_created = dataObject.time_created;

        let motion = jsonData[value || "motion"];

        console.log("motion and jsondata", motion, jsonData);

        tempHistory.push({
          date: time_created,
          motion: motion,
        });
      });

      console.log("tempHistory", tempHistory);

      setMotionCountHistory(tempHistory);
    } catch (error) {}
  }, [deviceHistory, value]);

  return (
    <Card sx={{ height: "100%" }}>
      <CardHeader title={"Motion Count History"} />
      <ResponsiveContainer aspect={4} width="100%">
        <AreaChart
          data={motionCountHistory}
          margin={{ top: 0, right: 30, left: 0, bottom: 0 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <defs>
            <linearGradient id="motion" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="5%"
                stopColor={theme.palette.primary.main}
                stopOpacity={0.8}
              />
              <stop
                offset="95%"
                stopColor={theme.palette.primary.main}
                stopOpacity={0}
              />
            </linearGradient>
          </defs>
          <XAxis
            dataKey="date"
            type={"category"} //format date to be more readable
            tickFormatter={(value) => {
              return formatDateOnly(value);
            }}
          />
          <YAxis
            dataKey="motion"
            type="number"
            yAxisId="1"
            //use domain to set min and max totalVolume, if min totalVolume is a minus, add -5 to it
          />
          <Tooltip
            contentStyle={{
              backgroundColor: theme.palette.background.default,
            }}
            cursor={false}
            labelFormatter={(label) => {
              return formatDate(label);
            }}
            //format label to capitalize first letter of each word and format value to use celsius symbol and format date

            formatter={(value, name) => {
              name = "Motion Detected";
              return [value + " times", name];
            }}
          />
          <Area
            yAxisId="1"
            dataKey="motion"
            stroke={theme.palette.primary.main}
            animationDuration={300}
            fill="url(#motion)"
            strokeWidth={2}
            dominantBaseline={"middle"}
          />
        </AreaChart>
      </ResponsiveContainer>
    </Card>
  );
}
