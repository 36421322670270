// Moved data parsing logic to a separate function
export const parseDeviceHistory = (deviceHistory, field) => {
  if (!deviceHistory || deviceHistory.length === 0) {
    return [];
  }

  try {
    return deviceHistory.map((dataObject) => {
      const jsonData = JSON.parse(dataObject.data);

      const time_created = dataObject.time_created;

      let value = null;

      console.log("jsonData", jsonData);

      if (field) {
        value = jsonData[field];
      } else {
        value = jsonData["motion"];
      }

      console.log("value", value);
      //true false
      if (value === 1) {
        value = "Yes";
      } else {
        value = "No";
      }

      return {
        Date: time_created,
        motion: value,
      };
    });
  } catch (error) {
    console.error(error);
    return [];
  }
};
